<template>
  <basic-page-card pageName="Manage Preferences" iconClass="fa fa-cog fa-lg">
    <v-wait for="loadingPreferences">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div>
        <b-table
          :items="preferences"
          :fields="tableFields"
          striped
          responsive="sm"
        >
          <template v-slot:cell(value)="data">
            {{ data.item.value === 'false' ? 'No' : 'Yes' }}
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              variant="primary"
              @click="editPreference(row)"
              :class="['mr-2']"
            >
              Edit
            </b-button>
          </template>
        </b-table>
      </div>
      <b-modal
        id="editPreference"
        ref="editPreference"
        :title="selectedPreference.name"
        size="lg"
        hide-footer
      >
        <div class="row px-3 align-items-baseline">
          <div class="col-sm-6">
            {{ selectedPreference.description }}
          </div>
          <div class="col-sm-4">
            <multiselect
              track-by="id"
              label="name"
              v-model="selectedOption"
              :options="yesNoOptions"
              :multiple="false"
              :close-on-select="true"
              placeholder="Please Select"
              class="simple-select form-control"
              :showLabels="false"
            >
            </multiselect>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-sm-12 mb-3 mt-4">
            <b-button
              type="submit"
              variant="secondary"
              class="btn btn-primary fw-btn mt-3 mx-3"
              @click="cancelUpdate"
              >Cancel</b-button
            >
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-primary fw-btn mt-3 mx-3"
              @click="updatePreference"
              >Update</b-button
            >
          </div>
        </div>
      </b-modal>
    </v-wait>
  </basic-page-card>
</template>

<script>
// api
import preferencesAPI from '@/api/finapps/preferences'
import userPreferencesAPI from '@/api/finapps/user_preferences'
// ui components
import Multiselect from 'vue-multiselect'

export default {
  name: 'Settings',
  components: {
    Multiselect
  },
  data() {
    return {
      preferences: [],
      userPreferences: [],
      selectedPreference: {},
      selectedOption: {},
      tableFields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'value', label: 'Preference', sortable: false },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      yesNoOptions: [
        {
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        }
      ]
    }
  },
  created() {
    this.loadInitData()
  },
  computed: {},
  methods: {
    loadInitData() {
      this.$wait.start('loadingPreferences')

      Promise.all([this.loadPreferences(), this.loadUserPreferences()]).then(
        () => {
          this.preferences = this.preferences.map(preference => {
            this.pr
            let userPreference = this.userPreferences.find(
              up => up.preference_id === preference.id
            )
            preference.user_preference_id = userPreference
              ? userPreference.id
              : null
            preference.value = userPreference ? userPreference.value : null
            return preference
          })
          this.$wait.end('loadingPreferences')
        }
      )
    },
    loadPreferences() {
      return preferencesAPI.all().then(res => {
        this.preferences = res.preferences
      })
    },
    loadUserPreferences() {
      return userPreferencesAPI.preferences().then(res => {
        this.userPreferences = res.user_preferences
      })
    },
    updatePreference() {
      this.selectedPreference.user_preference_id
        ? this.updateUserPreference()
        : this.createUserPreference()
    },
    createUserPreference() {
      return userPreferencesAPI
        .createPreference(this.selectedPreference.id, this.selectedOption.id)
        .then(() => {
          this.$toasted.show('Successfully updated user preferences.', {
            icon: 'user-circle',
            type: 'success'
          })
          this.loadInitData()
          this.$refs.editPreference.hide()
        })
    },
    updateUserPreference() {
      return userPreferencesAPI
        .updatePreference(
          this.selectedPreference.user_preference_id,
          this.selectedOption.id
        )
        .then(() => {
          this.$toasted.show('Successfully updated user preferences.', {
            icon: 'user-circle',
            type: 'success'
          })
          this.loadInitData()
          this.$refs.editPreference.hide()
        })
    },
    editPreference(row) {
      this.selectedPreference = row.item
      this.selectedOption = this.yesNoOptions.find(opt => {
        let optValue =
          this.selectedPreference.value === 'false' ? 'false' : 'true'
        return opt.id === optValue
      })
      this.$nextTick(() => {
        this.$refs.editPreference.show()
      })
    },
    cancelUpdate() {
      this.selectedPreference = {}
      this.selectedOption = {}
      this.$refs.editPreference.hide()
    }
  }
}
</script>
<style lang="scss" scoped></style>
