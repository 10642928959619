import Vue from 'vue'
import axios from 'axios'

export default {
  preferences() {
    return axios
      .get('/api/user_preferences', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  createPreference(preferenceID, value) {
    return axios
      .post(
        '/api/user_preferences',
        {
          user_preferences: {
            preference_id: preferenceID,
            value: value
          }
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Group with same financial institution already exists.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
          return Promise.reject('validation failed')
        }
      )
  },
  updatePreference(userPreferenceID, value) {
    return axios
      .put(
        `/api/user_preferences/${userPreferenceID}`,
        {
          value: value
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              `Validation failed. ${error.response.data.message[0]}`,
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
          return Promise.reject('validation failed')
        }
      )
  }
}
